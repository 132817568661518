button {
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    padding: 0;
    outline: 0;
}

label:hover,
button:hover {
    cursor: pointer;
}
