@import '~@/components/core-styling/core-styling.css';
@import '~@/components/browse-happy/browse-happy.css';

.page-not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.page-not-found .icon svg {
  height: 10.5em;
  width: 10.5em;
}

.page-not-found__header,
.page-not-found__message {
  animation-delay: 0.2s;
  display: block;
  font: var(--l);
  margin: 0;
}

.page-not-found__header {
  font-weight: bold;
  margin-top: 0.5em;
}

.page-not-found__message {
  max-width: 30em;
}

.page-not-found__button {
  border-radius: 50px;
  border: 2px solid;
  color: var(--text);
  display: inline-block;
  font-weight: bold;
  margin-top: 1.5em;
  padding: 0.5em 1em;
  text-decoration: none;
}
