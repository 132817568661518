*,
*::before,
*::after {
    box-sizing: border-box;
    outline-color: var(--tertiary);
}

@media (kiosk) {
    *,
    *::before,
    *::after {
        user-select: none;
    }
}

body {
    height: 100%;
    background-color: white;
    margin: 0;
}

body[data-is-modal-open] main {
    filter: blur(10px) opacity(0.5) brightness(150%);
    transform-origin: center 5%;
    transform: scale(1.05);
}

body[data-is-modal-open] {
    overflow-y: hidden;
    height: 100%;
    position: fixed; /* iOS fix */
    width: 100%;
}

body.loading::before {
    animation: loading 5s cubic-bezier(0.02, 0.01, 0.21, 1) forwards;
    background-color: var(--primary);
    content: "";
    height: 0.25em;
    left: 0;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    width: 100%;
    z-index: var(--layer-loading);
}

@media (kiosk) {
    main {
        padding: var(--spacing-3x);
    }
}

@media (web) {
    main {
        padding: var(--spacing-mobile);
    }

    .container {
        margin: 0 auto;
        max-width: 50rem;
        width: 100%;
    }
}


.visually-hidden {
    /* HTML5 Boilerplate accessible hidden styles */
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@keyframes loading {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(-2.5%);
    }
}
