.browse-happy {
    background-color: #ff805c;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: var(--text);
    display: none;
    left: 0;
    margin: 0;
    padding: 0.5rem;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1;
}

.browse-happy a {
    color: inherit;
    font-weight: bold;
}
