@font-face {
    font-family: 'NiveauGrotesk';
    src: url('~@/assets/fonts/NiveauGroteskRegular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'NiveauGrotesk';
    src: url('~@/assets/fonts/NiveauGroteskMedium.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'NiveauGrotesk';
    src: url('~@/assets/fonts/NiveauGroteskLight.otf') format('opentype');
    font-weight: lighter;
    font-style: normal;
    font-display: fallback;
}
