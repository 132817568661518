:root {
    --family: 'NiveauGrotesk', sans-serif;
    --bold: 500;
}

html {
    height: 100%;
    scroll-behavior: smooth;
    line-height: 1.57;
    letter-spacing: -0.14px;
    font-family: 'NiveauGrotesk', sans-serif;
    font-weight: normal;
    color: var(--text);
}

@media (kiosk) {
    :root {
        --xxl: 2.5rem/1.25 var(--family);
        --xl: 2.14rem/1.17 var(--family);
        --l: 1.57rem/1.36 var(--family);
        --m: 1rem/1.57 var(--family);
        --s: 0.93rem/1 var(--family);
        --xs: 0.85rem/1 var(--family);
        --xxs: 0.71rem/1 var(--family);
    }

    html {
        font-size: 20px;
    }
}

@media (web) {
    :root {
        --xxl: 2.1875rem/1.25 var(--family); /* 35px */
        --xl: 1.859rem/1.17 var(--family); /* 30px */
        --l: 1.375rem/1.36 var(--family); /* 22px */
        --m: 1rem/1.57 var(--family); /* 16px */
        --s: 0.93rem/1 var(--family); /* 13px */
        --xs: 0.85rem/1 var(--family); /* 12px */
        --xxs: 0.71rem/1 var(--family); /* 10px */
    }

    html {
        font-size: 16px;
    }
}

@media (max-width: 320px) {
    :root {
        --xxl: 2.125rem/1.25 var(--family); /* 34px */
    }
}
