.bubble {
    animation: fade-in 0.2s forwards 0.8s ease-in-out;
    background: var(--secondary);
    border-radius: 25px;
    border-top-left-radius: 5px;
    display: inline-block;
    margin-bottom: 1em;
    opacity: 0;
    transform: scale(0);
    transform-origin: top left;
    padding: 0.875em 1em;
    width: auto;
}

.bubble.when-done {
    animation: none;
    opacity: 1;
    transform: scale(1);
}

article:first-of-type > .bubble {
    animation-delay: 1.6s;
}

.bubble--down {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 5px;
    margin-bottom: 0.5rem;
}

.bubble--inline p {
    display: inline-block;
}

.bubble--nojavascript {
    background-color: var(--error);
    border-bottom-left-radius: 5px;
    color: white;
    margin-bottom: 0.5rem;
}

.bubble p {
    margin: 0;
}
