.rich-text {
    font: var(--m);
}

.rich-text p:first-child {
    margin-top: 0;
}

.rich-text strong {
    font-weight: bold;
}

.rich-text ul, .rich-text ol {
    list-style-type: none;
    padding: 0;
}

.rich-text li:before {
    content: "-";
    padding-right: 8px;
}

.rich-text li p {
    display: inline;
}

.rich-text strong,
.rich-text ul span {
    display: contents;
}
