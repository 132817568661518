:root {
    --warning: #ff805c;
    --error: #ec3658;
    --error-modal: #3e3b3c;
    --text: #151515;
    --primary: #72cec1;
    --secondary: #f3f6f7;
    --tertiary: #677ee1;
    --shade: #f2f2f2;

    /* Gradients */
    --neutral-lg: linear-gradient(180deg, rgba(243,246,246,1) 0%, rgba(234, 239, 241, 1) 100%);
    --warning-lg: linear-gradient(180deg, rgba(255,128,92,1) 0%, rgba(232,115,82,1) 100%);
    --error-lg: linear-gradient(180deg, rgba(236,52,88,1) 0%, rgba(216,54,87,1) 100%);
    --primary-lg: linear-gradient(180deg, rgba(108,224,207,1) 0%, rgba(42,203,178,1) 100%);
    --tertiary-lg: linear-gradient(180deg, rgba(106,117,225,1) 0%, rgba(97,107,208,1) 100%);

    /* Spacing */
    --spacing-unit: 1rem;
    --spacing-1x: var(--spacing-unit);
    --spacing-2x: calc(2 * var(--spacing-unit));
    --spacing-3x: calc(3 * var(--spacing-unit));
    --spacing-4x: calc(4 * var(--spacing-unit));
    --spacing-5x: calc(5 * var(--spacing-unit));
    --spacing-6x: calc(6 * var(--spacing-unit));
    --spacing-8x: calc(8 * var(--spacing-unit));
    --spacing-mobile: calc(1.5 * var(--spacing-unit));

    /* Z-index */
    --layer-raised: 2;
    --layer-raised-2: 4;
    --layer-raised-3: 6;
    --layer-overlay: 10;
    --layer-modal: 11;
    --layer-error: 12;
    --layer-loading: 15;
}

@custom-media --viewport-medium (min-width: 600px);
@custom-media --viewport-large (min-width: 900px);
